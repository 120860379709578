//@flow
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Arrow from '../icons/Arrow';
import Solutions from '../icons/Solutions';
import WhatWeTreat from '../icons/WhatWeTreat';
import { purple } from '../style/colors';
import Section from './Section';
import type { Node } from 'react';

const Button = ({
  label,
  avatar,
  to,
}: {
  label: string,
  avatar: Node,
  to: string,
}) => (
  <StyledButtonBase onClick={() => navigate(to)} style={{ margin: 8 }}>
    <Card>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: 16,
        }}
      >
        {avatar}
        <Label>{label}</Label>
        <StyledArrow />
      </CardContent>
    </Card>
  </StyledButtonBase>
);

export default ({
  body,
  team = true,
  services = true,
  conditions = true,
}: {
  body?: string,
  team?: boolean,
  conditions?: boolean,
  services?: boolean,
}) => {
  return (
    <Section skewed={false} noBackground={true}>
      <Header>
        <H1>Learn more about us</H1>
      </Header>
      {body && <Body>{body}</Body>}
      <Links>
        {team && (
          <Button
            avatar={<Avatar style={{ marginRight: 16 }}>TM</Avatar>}
            to="/team"
            label="Meet our team"
          />
        )}
        {conditions && (
          <Button
            avatar={
              <WhatWeTreat style={{ marginRight: 16, width: 40, height: 40 }} />
            }
            to="/conditions"
            label="See what we treat"
          />
        )}
        {services && (
          <Button
            avatar={
              <Solutions style={{ marginRight: 16, width: 40, height: 40 }} />
            }
            to="/services"
            label="Learn about our services"
          />
        )}
      </Links>
    </Section>
  );
};

const StyledButtonBase = styled(ButtonBase)`
  && {
    padding: 0;
    display: block;
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }
`;

const StyledArrow = styled(Arrow)`
  && {
    fill: ${purple[500]};
  }
`;

const Label = styled.span`
  && {
    font-weight: 500;
    flex: 1;
    text-align: left;
  }
`;

const Links = styled.div`
  && {
    max-width: 500px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
  }
`;
const Header = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const H1 = styled.h1`
  && {
    margin-top: 20px;
  }
`;

const Body = styled.p`
  && {
    max-width: 400px;
    margin: auto;
  }
`;
