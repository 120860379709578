//@flow
import React from 'react';

export default ({
  className,
  style,
  odd,
}: {
  className?: string,
  style?: any,
  odd?: boolean,
}) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={odd ? '#555ABF' : '#87BBFD'}
      d="M11.5 0C17.852 0 23 5.15 23 11.5 23 17.852 17.852 23 11.5 23 5.15 23 0 17.852 0 11.5 0 5.15 5.15 0 11.5 0z"
    />
    <path
      fill={odd ? '#fff' : '#7356B6'}
      transform="translate(5 5.97)"
      d="M10.766.274L3.65 7.56 1.72 5.3C.876 4.568-.45 5.42.152 6.513l2.29 3.965c.363.486 1.207.972 2.05 0 .363-.486 7.237-9.11 7.237-9.11.846-.973-.24-1.822-.962-1.094h-.002z"
    />
  </svg>
);
