// @flow
import { graphql } from "gatsby";
// @flow
import React from "react";
import styled from "styled-components";
import ArrowLink from "../components/ArrowLink";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import LearnMore from "../components/LearnMore";
import Page from "../components/Page";
import Section from "../components/Section";
import CheckMark from "../icons/Check";
import Heart from "../icons/Heart";
import { query, MEDIUM_BREAKPOINT } from "../style";

import type { ImageSharp, ImageType } from "../types";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType
  }
};

const pWidth = 500;

export default class Index extends React.Component<Props> {
  render() {
    const { mainImage, secondImage, thirdImage } = this.props.data;
    return (
      <Page title="Consultation">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Your consultation"
          subtitle="Starting this process may seem overwhelming, but we are here to make sure you are informed, confident, and set up for success. Our team will listen to you and work dilligently to make a diagnosis and form a treatment plan that meets your personal needs."
          cta="Make an appointment"
        />
        <StyledSection>
          <h1>Request an appointment</h1>
          <p style={{ maxWidth: pWidth }}>
            Give us a call and see how easy it is to schedule an appointment,
            quickly and painlessly. Our Patient Support Team is specifically
            trained to speak with you about your symptoms and concerns. Please
            be prepared to send over any medical records, MRIs or other
            diagnostic imaging you may have, so that our team can thoroughly
            assess your medical history, which may impact the care plan we
            discuss and ultimately proceed with.
          </p>
          <ArrowLink to="/page-2/">Request an appointment</ArrowLink>
        </StyledSection>

        <Section color="#e0ecfa">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem"
            }}
          >
            <Heart />
            <Title>What to expect</Title>
          </div>
          <Items>
            <Item>
              <TitleWrap>
                <CheckMark />
                <Head>We care</Head>
              </TitleWrap>
              <Body>
                Our practice is built on trust and empathy, we are committed to
                setting you up for success and alleviating any fears or
                concerns. You can expect us to spend as much time as needed
                discussing your symptoms, exploring possible diagnosises and
                treatment options and answering any questions you may have.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark odd />
                <Head>Full diagnostic evaluation</Head>
              </TitleWrap>
              <Body>
                An accurate diagnosis is the foundation for a successful
                treatment. The Spine Institute of Idaho is equipped with the
                most modern imaging tools and technology including MRIs, X-Rays
                and EMGs. We will utilize each and every resource we have at our
                disposal, to assist with a thorough and correct diagnosis.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark odd />
                <Head>Personalized treatment</Head>
              </TitleWrap>
              <Body>
                Too often, patients are treated as a number and not an
                individual. We pride ourselves on not just knowing your symptoms
                and conditions, but also, who you are, what you love and what’s
                important to you. We strongly feel that better outcomes happen
                when we know and understand the unique individuals who see us
                for help.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark />
                <Head>A multidisciplinary approach</Head>
              </TitleWrap>
              <Body>
                We do SO MUCH more than just surgery! We offer everything from
                Physical Medicine and Acupuncture to Physiatry, and have a
                variety of pain management options to fit your needs. Our team
                will do everything possible to make sure you are confident in
                your personal treatment plan.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark />
                <Head>Modern treatment options</Head>
              </TitleWrap>
              <Body>
                Our surgeons and specialists are trained in the most cutting
                edge, advanced techniques, from minimally invasive surgery, to
                spinal cord stimulation. We seek out the newest treatment
                options, and keep up on the newest medical advances, we feel it
                is not only a necessity, but one of our passions.
              </Body>
            </Item>
          </Items>
        </Section>
        <LearnMore />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const StyledSection = styled(Section)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
    padding-top: 180px;
  `};
  }
`;

const HeadWrap = styled.div`
  && {
  }
`;

const TitleWrap = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

const Items = styled.div`
  && {
    ${query(500)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px -10px;
  `};
  }
`;

const Item = styled.div`
  && {
    max-width: ${pWidth}px;

    ${query(500)`
    flex: 1 0 0%;
    min-width: 340px;
    margin: 0px 10px;
  `};
  }
`;

const Title = styled.h1`
  && {
    text-align: center;
    margin-top: 2rem;
  }
`;

const Head = styled.h3`
  && {
    margin: 0;
    margin-left: 10px;
  }
`;

const Body = styled.p`
  && {
  }
`;

export const pageQuery = graphql`
  query consultationPage {
    mainImage: file(relativePath: { eq: "square/sam-3.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/sam.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/viewing-computer.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
  }
`;
