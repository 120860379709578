//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <use fill="#68D4F8" fillOpacity=".3" xlinkHref="#a12" />
    <use fill="#7356B6" xlinkHref="#b12" transform="translate(33 48)" />
    <use fill="#BEB0F4" xlinkHref="#c12" transform="translate(37.13 22)" />
    <use fill="#7356B6" xlinkHref="#d12" transform="translate(19 22)" />
    <defs>
      <path
        id="a12"
        d="M36 72c19.882 0 36-16.118 36-36S55.882 0 36 0 0 16.118 0 36s16.118 36 36 36z"
      />
      <path
        id="b12"
        d="M4 0H2C.895 0 0 .895 0 2v1c0 1.105.895 2 2 2h2c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2z"
      />
      <path
        id="c12"
        d="M0 11.96c1.58-3.047 3.82-5.702 6.56-7.77L3.08.71C2.69.32 2.81 0 3.36 0h11.52c.263.003.515.11.7.296.186.188.29.44.29.704v11.5c0 .55-.32.68-.7.3l-4.35-4.35c-3.44 1.88-6.35 6.36-7.86 12.02-.63-2.95-1.624-5.807-2.96-8.51z"
      />
      <path
        id="d12"
        d="M9.3 4.2L12.8.7c.38-.38.26-.7-.3-.7H1C.735 0 .48.105.293.293.105.48 0 .735 0 1v11.5c0 .55.32.68.7.3l4.35-4.35C10.09 11.2 14 19.53 14 29h6C20 18.23 15.53 8.67 9.3 4.2z"
      />
    </defs>
  </svg>
);
