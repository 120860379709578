// @flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="60"
    viewBox="0 0 62 60"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#87BBFD"
      d="M48.14 26.512c-3.516 0-6.888-1.397-9.374-3.883-2.486-2.487-3.882-5.858-3.882-9.374 0-3.516 1.396-6.888 3.882-9.373C41.252 1.397 44.624 0 48.14 0c3.515 0 6.887 1.397 9.373 3.883C60 6.368 61.395 9.74 61.395 13.256c0 3.515-1.396 6.887-3.882 9.373-2.486 2.485-5.858 3.882-9.373 3.882zM13.255 60c-3.516 0-6.888-1.397-9.373-3.883C1.397 53.633 0 50.26 0 46.745c0-3.516 1.397-6.887 3.883-9.373 2.485-2.485 5.857-3.882 9.373-3.882 3.515 0 6.887 1.397 9.373 3.883 2.485 2.487 3.882 5.858 3.882 9.374 0 3.516-1.397 6.888-3.883 9.373C20.142 58.603 16.77 60 13.255 60z"
    />
    <path
      fill="#6772E5"
      d="M48.14 60c-3.516 0-6.888-1.397-9.374-3.883-2.486-2.485-3.882-5.857-3.882-9.373 0-3.516 1.396-6.887 3.882-9.373 2.486-2.485 5.858-3.882 9.373-3.882 3.515 0 6.887 1.397 9.373 3.883 2.486 2.487 3.882 5.858 3.882 9.374 0 3.516-1.396 6.888-3.882 9.373C55.027 58.603 51.655 60 48.14 60zm5.773-19.62l-8.655 8.855-2.347-2.657c-1.026-.883-2.64.15-1.906 1.48l2.786 4.723c.44.59 1.468 1.18 2.495 0 .442-.59 8.805-11.07 8.805-11.07 1.025-1.18-.293-2.213-1.172-1.326l-.005-.005zm-7.63-21.09c-1.025 1.18-2.05.59-2.493 0l-2.786-4.724c-.734-1.333.88-2.365 1.907-1.48l2.348 2.658 8.655-8.857c.88-.886 2.2.148 1.173 1.33 0 0-8.36 10.478-8.8 11.067l-.002.005zM13.257 26.51c-3.516 0-6.888-1.397-9.373-3.883C1.397 20.142 0 16.77 0 13.255 0 9.74 1.397 6.368 3.883 3.883 6.368 1.397 9.74 0 13.256 0c3.515 0 6.887 1.397 9.373 3.883 2.485 2.485 3.882 5.857 3.882 9.373 0 3.515-1.397 6.887-3.883 9.373-2.487 2.485-5.858 3.882-9.374 3.882zM19.028 6.89l-8.654 8.857-2.347-2.657c-1.027-.883-2.64.15-1.907 1.48l2.787 4.72c.44.592 1.467 1.183 2.494 0 .444-.59 8.806-11.07 8.806-11.07 1.027-1.178-.293-2.212-1.175-1.324l-.002-.006zm-11 39.687l2.346 2.658 8.654-8.856c.88-.886 2.202.146 1.173 1.327.003 0-8.358 10.48-8.8 11.07-1.023 1.18-2.05.59-2.49 0l-2.79-4.72c-.734-1.33.88-2.365 1.907-1.48z"
    />
  </svg>
);
