// @flow
import React from 'react';
import styled from 'styled-components';
import { MAX_WIDTH } from '../style';

export default ({
  style,
  children,
  className,
  color,
  skewed = true,
  noBackground = false,
  backgroundStyle,
}: {
  style?: any,
  children: any,
  className?: string,
  color?: string,
  skewed?: boolean,
  noBackground?: boolean,
  backgroundStyle?: any,
}) => (
  <Wrapper style={style}>
    <MaxWidth className={className}>{children}</MaxWidth>
    <Background
      style={backgroundStyle}
      noBackground={noBackground}
      color={color}
      skewed={skewed}
    />
  </Wrapper>
);

const Background = styled.div`
  && {
    display: ${({ noBackground }) => (noBackground ? 'none' : 'block')};
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ flatBottom }) => (flatBottom ? '150%' : '100%')};
    /* transform: ${({ skewed }) => skewed && 'skewY(-12deg)'}; */
    background: ${({ color }) => (color ? color : 'white')};
  }
`;

const MaxWidth = styled.div`
  && {
    position: relative;
    max-width: ${MAX_WIDTH}px;
    margin: auto;
    padding: 100px 20px;
    z-index: 100;
  }
`;

const Wrapper = styled.section`
  && {
    position: relative;
  }
`;
