// @flow
import PhoneIcon from '@material-ui/icons/Phone';
import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { MAX_WIDTH } from '../style';
import { purple } from '../style/colors';
import { telUrl } from '../utils/constants';
import Button from './button';

export default class Footer extends React.Component<any> {
  render() {
    return (
      <SectionWrapper>
        <Wrapper>
          <Title>Ready to get started?</Title>
          <Sub>Make an appointment, or give us a call.</Sub>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <DivButton
              onClick={() => navigate('/request-an-appointment')}
              label="Make an appointment"
              color="primary"
              arrow
            />
            <DivButton
              onClick={() =>
                window && window.innerWidth < 600
                  ? (window.location.href = telUrl)
                  : navigate('/contact')
              }
            >
              <StyledPhoneIcon /> Call us
            </DivButton>
          </div>
        </Wrapper>
      </SectionWrapper>
    );
  }
}

const DivButton = styled(Button)`
  && {
    margin: 10px;
    text-align: center;
  }
`;

const Title = styled.h1`
  && {
    color: ${purple[500]};
    color: white;
    font-weight: 300;
  }
`;

const Sub = styled.h2`
  && {
    margin-top: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: white;
  }
`;

const SectionWrapper = styled.div`
  && {
    background: ${({ color }) => (color ? color : purple[500])};
  }
`;

const Wrapper = styled.div`
  && {
    padding: 20px;
    padding-bottom: 60px;
    max-width: ${MAX_WIDTH}px;
    margin: auto;
  }
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  && {
    height: 20px;
    margin-right: 10px;
  }
`;
