//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
  >
    <path fill="#7356B6" d="M36 72a36 36 0 1 0 0-72 36 36 0 0 0 0 72z" />

    <path
      fill="#D2F2FD"
      transform="translate(21.8 23.25)"
      fillRule="evenodd"
      d="M14.17 26l-2.06-1.87C4.81 17.5 0 13.15 0 7.79 0 3.43 3.43 0 7.8 0a8.48 8.48 0 0 1 6.37 2.96A8.48 8.48 0 0 1 20.54 0c4.37 0 7.8 3.43 7.8 7.8 0 5.35-4.82 9.71-12.12 16.34L14.17 26z"
    />
  </svg>
);
