// @flow
import React from 'react';
import styled from 'styled-components';
import Arrow from '../icons/Arrow';
import { purple } from '../style/colors';
import Button from './button';
import Link from './GatsbyLink';
import type { Node } from 'react';

export const ClassicArrow = ({
  children,
  style,
  className,
  to,
  ...rest
}: {
  children?: Node,
  className?: string,
  style?: any,
  to: string,
  rest?: $Rest<any, any>,
}) => (
  <PurpleLink to={to} style={style} className={className} {...rest}>
    {children}
    <SmallArrow />
  </PurpleLink>
);

export default ({
  children,
  style,
  className,
  to,
  ...rest
}: {
  children?: Node,
  className?: string,
  style?: any,
  to: string,
  rest?: $Rest<any, any>,
}) => (
  <Button
    to={to}
    component={Link}
    stroked
    className={className}
    style={style}
    {...rest}
  >
    {children}
    <SmallArrow />
  </Button>
);

const PurpleLink = styled(Link)`
  && {
    color: ${purple[500]};
    fill: ${purple[500]};
    background: none;
    text-decoration: none;
    text-shadow: none;

    display: inline-flex;
    flex-direction: row;
    align-items: center;

    font-weight: 400;

    &:hover {
      opacity: 0.8;
      box-shadow: inset 0 -5px 0 ${purple[100]};
    }
  }
`;

const SmallArrow = styled(Arrow)`
  && {
    width: 14px;
    margin-left: 5px;
  }
`;
